import { Add, Edit, RemoveCircleOutline, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { apis } from 'api/api';
import { ActionsDropdown } from 'components/Table/TableActions';
import { useApi } from 'hooks';
import { useTableSearch } from 'pages/Settings/context';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store';
import { useDialogStore, useItemStore } from 'store/useStore';
import { StoreKeys } from 'utils';
import { DataTable } from '../../components/Table/Table';
import { TableSkeleton } from '../../components/Table/TableSkeleton';
import { colorPalette } from '../../theme/colors';
import DeleteDialog from './common/DeleteDialog';
import { EditDialog } from './common/EditDialog';

const ModuleListTableColumns = [
  { id: 'title', label: 'Module Name' },
  { id: 'category', label: 'Category' },
  { id: 'playbookType', label: 'Playbook Type' },
  { id: 'createdAt', label: 'Created On' },
  // { id: 'createdBy', label: 'Created By' },
  { id: 'status', label: 'Status' },
  { id: 'spaceOwnership', label: 'Space Ownership' },

  { id: 'actions', label: 'Actions' },
];
const DialogOpenKey = 'moduleDetails';

export const ModuleList = () => {
  const user = useAuthStore((state) => state.loggedUser);
  const setDeleteDialog = useDialogStore((state) => state.setDeleteDialog);
  const setEditDialog = useDialogStore((state) => state.setEditDialog);

  const itemStore = useItemStore((state) => state.items);
  const addItemToStore = useItemStore((state) => state.addItem);
  const updateItemStore = useItemStore((state) => state.updateItem);

  const navigate = useNavigate();
  const { call: fetchModules, isLoading: isFetching } = useApi({
    fetcher: () => {
      return apis.getModuleList({ organizationId: user.organization });
    },
    onSuccess: (updatedData) => {
      addItemToStore({ key: StoreKeys.MODULES, value: updatedData });
    },
  });

  const { call: deleteModule } = useApi({
    fetcher: apis.deleteModule,
    successMessage: 'Module deleted successfully',
  });

  useEffect(() => {
    fetchModules();
  }, []);

  const searchFields = ['title', 'category'];
  const { setSearchTerm, filteredData } = useTableSearch({
    data: itemStore.modules,
    searchFields,
    filter: (item) => item.status !== 'archive',
  });

  const actions = (data) => {
    return [
      {
        label: 'View/Edit Details',
        icon: <Edit color="success" />,
        onClick: () => {
          setEditDialog({ isOpen: true, data, forItem: DialogOpenKey });
        },
      },
      {
        label: 'Remove Module',
        icon: <RemoveCircleOutline color="error" />,
        onClick: () =>
          setDeleteDialog({
            isOpen: true,
            forItem: 'Module',
            onOk: async () => {
              await deleteModule({
                moduleId: data._id,
                data: {
                  status: 'archive',
                },
              });

              const updatedList = itemStore.modules.filter(
                (module) => module._id !== data._id
              );
              updateItemStore({
                key: StoreKeys.MODULES,
                value: updatedList,
              });
            },
          }),
      },
    ];
  };

  const adminSearchBar = (
    <Stack direction="row" spacing={1}>
      <FormControl size="small">
        <OutlinedInput
          id="outlined-adornment-amount"
          placeholder="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
        />
      </FormControl>
    </Stack>
  );

  const header = (
    <Stack
      direction={'row'}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5">Modules</Typography>
    </Stack>
  );

  return (
    <Stack direction={'column'} gap={5}>
      {header}
      <Divider />
      <Box
        sx={{
          background: colorPalette.background.paper,
          padding: 2,
          borderRadius: 2,
        }}
      >
        <Stack direction="column" spacing={5}>
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Button
              onClick={() => {
                navigate('/module/create');
              }}
              variant="contained"
              startIcon={<Add />}
            >
              New Module
            </Button>
            {adminSearchBar}
          </Stack>
          {isFetching ? (
            <TableSkeleton column={ModuleListTableColumns} />
          ) : (
            <DataTable
              columns={ModuleListTableColumns}
              sort={true}
              data={filteredData?.map((item) => ({
                ...item,
                actions: <ActionsDropdown actions={actions(item)} />,
                name: `${item.firstName} ${item.lastName}`,
                createdAt: new Date(item.createdAt).toDateString(),
                status: (
                  <Chip
                    label={item.status}
                    variant="outlined"
                    size="small"
                    color={item.status === 'ACCEPTED' ? 'success' : 'info'}
                  />
                ),
              }))}
            />
          )}
        </Stack>
      </Box>
      <DeleteDialog />
      <EditDialog forItem={DialogOpenKey} />
    </Stack>
  );
};
