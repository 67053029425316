import { yupResolver } from '@hookform/resolvers/yup';
import {
  Assistant,
  Checklist,
  SpaceDashboard,
  Translate,
} from '@mui/icons-material';
import apis from 'api/api';
import EditDialogWrapper from 'components/Dialog/DialogWrapper';
import LoadingOverlay from 'components/LoadingOverlay';
import { Tab } from 'components/Tab';
import { useApi } from 'hooks';
import { UserProvider } from 'pages/Settings/context';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDialogStore, useItemStore } from 'store';
import { JourneyDetails } from '../JourneyDetails';
import { ModuleSelection } from '../ModuleSelection';
import { ModuleTranslation } from '../ModuleTranslation';
import { journeyDetails } from './schema';
import { useJourney } from './useJourney';

export const EditDialog = ({ forItem = '' }) => {
  const [aiModules, setAIModules] = useState([]);
  const { editJourney } = useJourney();
  const [aiGenModuleDetails, setAiGenModuleDetails] = useState([]);
  const [modules, setModules] = useState([]);
  const dialogState = useDialogStore((state) => state.editDialog);
  const deleteItemStore = useItemStore((state) => state.deleteItem);

  const journeyId = dialogState?.data?._id;

  const methods = useForm({
    resolver: yupResolver(journeyDetails),
    mode: 'onChange',
  });

  const {
    call: getJourneyDetails,
    data: journeyInfo,
    isLoading: journeyIsLoading,
  } = useApi({
    fetcher: apis.getJourney,
  });

  const { call: createJourneyModules, data: aiGenModules } = useApi({
    fetcher: apis.createJourneyModules,
    onSuccess: (res) => {
      setAIModules([{ moduleId: res?.aiModuleId }]);
    },
  });

  const { batchCall: fetchModuleDetails, isLoading: isModuleDetailsLoading } =
    useApi({
      fetcher: apis.getModuleById,
    });

  const loading = journeyIsLoading || isModuleDetailsLoading;

  const handleFetchModuleDetails = async () => {
    try {
      const moduleRequests = journeyInfo.translatedModule.map(
        async (module) => {
          const originalModuleResponse = await fetchModuleDetails([
            [{ moduleId: module.originalModule }],
          ]);

          const translatedModuleResponse = await fetchModuleDetails([
            [{ moduleId: module.translatedModule }],
          ]);

          const originalData = originalModuleResponse?.[0] || {};
          const translatedData = translatedModuleResponse?.[0] || {};

          return {
            originalModule: originalData,
            translatedModule: translatedData,
          };
        }
      );

      const moduleDetails = await Promise.all(moduleRequests);

      setAiGenModuleDetails(moduleDetails);
    } catch (error) {
      console.error('Error fetching module details:', error);
    }
  };

  const handleFetchAiModules = async () => {
    try {
      const moduleRequests = journeyInfo.aiModuleId.map(async (moduleId) => {
        const response = await fetchModuleDetails([[{ moduleId }]]);
        return response?.[0] || {};
      });

      const moduleDetails = await Promise.all(moduleRequests);

      setModules(moduleDetails);
    } catch (error) {
      console.error('Error fetching AI module details:', error);
    }
  };

  useEffect(() => {
    if (journeyInfo?.aiModuleId) {
      setAIModules([{ moduleId: journeyInfo?.aiModuleId }]);
    }
    handleFetchAiModules();
    handleFetchModuleDetails();
  }, [journeyInfo]);

  // TODO : Problem
  useEffect(() => {
    if (journeyId) {
      (async () => {
        await getJourneyDetails({ journeyId });
      })();
    }
  }, [journeyId]);

  const handleEdit = async (value) => {
    await editJourney(value);
    methods.reset();
  };

  const areModuleArraysEqual = (arr1 = [], arr2 = []) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    return arr1.every((module1) => {
      const matchingModule = arr2.find(
        (module2) => module2.moduleId === module1.moduleId
      );
      if (!matchingModule) {
        return false;
      }

      const sections1 = new Set(module1.sections);
      const sections2 = new Set(matchingModule.sections);

      if (sections1.size !== sections2.size) {
        return false;
      }

      return [...sections1].every((section) => sections2.has(section));
    });
  };

  const handleClickModuleRecom = async () => {
    const currentSelectedModules = useItemStore?.getState()?.items;

    const isModulesIdentical = areModuleArraysEqual(
      await currentSelectedModules?.selectedModules,
      await journeyInfo?.modules
    );

    if (!isModulesIdentical) {
      await createJourneyModules({
        modules: currentSelectedModules?.selectedModules,
        journeyId: journeyInfo._id,
      });
    }
  };

  const TabItems = useMemo(() => {
    const baseItems = [
      {
        label: 'Journey Details',
        value: 'journey_details',
        icon: <Checklist />,
        content: <JourneyDetails />,
      },
      {
        label: 'Module Selection',
        value: 'module_selection',
        icon: <SpaceDashboard />,
        content: <ModuleSelection selected={journeyInfo?.modules} />,
        onClick: async () => {
          if (journeyId) {
            await getJourneyDetails({ journeyId });
          }
        },
      },
      {
        label: 'Recommendation',
        value: 'module_recommendation',
        icon: <Assistant />,
        content: <ModuleSelection aiRecommended={true} selected={aiModules} />,
        onClick: () => {
          handleClickModuleRecom();
        },
      },
      {
        label: 'Translation',
        value: 'module_translation',
        icon: <Translate />,
        content: (
          <ModuleTranslation
            journeyDetails={journeyInfo}
            translatedData={aiGenModuleDetails}
            language={journeyInfo?.targetLanguage}
            modules={modules}
          />
        ),
      },
    ];

    return baseItems;
  }, [journeyId, journeyInfo, aiModules, aiGenModuleDetails, modules]);

  return (
    <UserProvider userType={'PARTNER'}>
      {loading ? <LoadingOverlay /> : null}
      <FormProvider {...methods}>
        <EditDialogWrapper
          forItem={forItem}
          onSubmit={methods.handleSubmit(handleEdit)}
          sx={{ width: '75vw' }}
          onClose={() => {
            methods.reset();
            deleteItemStore({ key: 'selectedModules' });
          }}
        >
          <Tab tabItems={TabItems} />
        </EditDialogWrapper>
      </FormProvider>
    </UserProvider>
  );
};
