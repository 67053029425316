import { Search, Visibility } from '@mui/icons-material';
import GetAppIcon from '@mui/icons-material/GetApp';
import {
  Backdrop,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import apis from 'api/api';
import { DynamicLoadingChecklist } from 'components/CustomMUI';
import { ActionsDropdown, DataTable, TableSkeleton } from 'components/Table';
import { useApi } from 'hooks';
import { useTableSearch } from 'pages/Settings/context';
import { useEffect, useState } from 'react';
import { useAuthStore, useDialogStore, useItemStore } from 'store';
import { handleExportPPTX, StoreKeys } from 'utils';
import { getOriginalZonedDate } from 'utils/date';
import { EditDialog as ModuleEditDialog } from '../Module/common/EditDialog';
const ModuleSelectionTableColumns = [
  { id: 'title', label: 'Module Name' },
  { id: 'category', label: 'Category' },
  { id: 'playbookType', label: 'Playbook Type' },
  { id: 'createdAt', label: 'Created On' },
  { id: 'status', label: 'Status' },
  { id: 'actions', label: 'Actions' },
];
const DialogOpenKey = 'ModuleDetails';

export const ModuleSelection = ({
  modules = [],
  selected = [],
  aiRecommended = false,
  loadingText = '',
}) => {
  const user = useAuthStore((state) => state.loggedUser);
  const itemStore = useItemStore((state) => state.items);
  const addItemToStore = useItemStore((state) => state.addItem);
  const dialogState = useDialogStore((state) => state.editDialog);
  const setEditDialog = useDialogStore((state) => state.setEditDialog);
  const [exporting, setExporting] = useState({});
  const [selectedModules, setSelectedModules] = useState(selected);
  const [checklistItems, setChecklistItems] = useState([]);
  const [exportModuleTitles, setExportModuleTitles] = useState('');

  const { call: generatePPT } = useApi({
    fetcher: apis.generatePPT,
    successMessage: 'PPT generated successfully',
  });

  const handleModuleChange = (moduleId, isChecked) => {
    setSelectedModules((prev) => {
      if (isChecked) {
        if (!prev.some((mod) => mod.moduleId === moduleId)) {
          return [...prev, { moduleId, sections: [] }];
        }
      } else {
        return prev.filter((mod) => mod.moduleId !== moduleId);
      }
      return prev;
    });
  };

  const handleSectionChange = (moduleId, sectionId, isChecked) => {
    setSelectedModules((prev) => {
      const moduleIndex = prev.findIndex((mod) => mod.moduleId === moduleId);
      if (moduleIndex > -1) {
        const updatedModules = [...prev];
        const module = updatedModules[moduleIndex];

        if (isChecked) {
          if (!module.sections.includes(sectionId)) {
            module.sections.push(sectionId);
          }
        } else {
          module.sections = module.sections.filter(
            (section) => section !== sectionId
          );
          if (module.sections.length === 0) {
            updatedModules.splice(moduleIndex, 1);
          }
        }
        return updatedModules;
      } else {
        return [...prev, { moduleId, sections: [sectionId] }];
      }
    });
  };

  const { call: fetchModules, isLoading: isFetching } = useApi({
    fetcher: () => {
      return apis.getModuleList({ organizationId: user.organization });
    },
    onSuccess: (updatedData) => {
      addItemToStore({ key: StoreKeys.MODULES, value: updatedData });
    },
  });

  const searchFields = ['title'];
  const { setSearchTerm, filteredData } = useTableSearch({
    data: modules?.length
      ? modules
      : itemStore?.modules?.length
      ? itemStore?.modules
      : [],
    searchFields,
    filter: (item) => {
      if (aiRecommended && selected.length) {
        return selected[0].moduleId.includes(item._id);
      }
      return item.status !== 'archive';
    },
  });

  useEffect(() => {
    if (!modules.length) fetchModules();
  }, []);

  useEffect(() => {
    setChecklistItems([
      {
        id: 'exportModule',
        label: `Preparing ${exportModuleTitles} For Export`,
        isComplete: false,
        apiCall: generatePPT,
      },
    ]);
  }, [exportModuleTitles]);

  useEffect(() => {
    if (selectedModules.length && !aiRecommended) {
      addItemToStore({ key: 'selectedModules', value: selectedModules });
    }
  }, [selectedModules]);

  const handleExport = async (moduleId, moduleTitle) => {
    setExportModuleTitles(moduleTitle);
    try {
      setExporting((prev) => ({ ...prev, [moduleId]: true }));

      await handleExportPPTX({
        id: moduleId,
        title: moduleTitle,
        call: generatePPT,
      });
    } catch (error) {
      console.error('Error generating PPT:', error);
    } finally {
      setExporting((prev) => ({ ...prev, [moduleId]: false }));
    }
  };

  const LoadingOverlay = ({ open, message }) => {
    return (
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
        open={open}
      >
        <CircularProgress color="inherit" />
        {message && (
          <Typography variant="h6" align="center">
            {message}
          </Typography>
        )}
      </Backdrop>
    );
  };

  const searchBar = (
    <Stack direction="row" spacing={1}>
      <FormControl size="small">
        <OutlinedInput
          id="outlined-adornment-amount"
          placeholder="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
        />
      </FormControl>
    </Stack>
  );

  return (
    <Stack direction="column" spacing={5}>
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h6"></Typography>
        {searchBar}
      </Stack>

      {isFetching ? (
        <TableSkeleton column={ModuleSelectionTableColumns} />
      ) : loadingText ? (
        <LoadingOverlay open={loadingText} message={loadingText} />
      ) : (
        <>
          <DataTable
            columns={ModuleSelectionTableColumns}
            sort={true}
            collapseContent={(row) => (
              <div>
                <strong>Details:</strong> {row.title}
              </div>
            )}
            data={filteredData?.map((item) => {
              const isItemSelected = aiRecommended
                ? true
                : selectedModules.some(
                    (module) => module.moduleId === item._id
                  );

              return {
                ...item,
                title: (
                  <>
                    <DynamicLoadingChecklist
                      isLoading={exporting[item._id]}
                      checklistItems={checklistItems}
                      title={`Exporting Module Data To PPTX. Please Wait ..`}
                    />
                    <Checkbox
                      disabled={aiRecommended}
                      size="xs"
                      color="primary"
                      checked={isItemSelected}
                      onClick={(e) =>
                        handleModuleChange(item._id, e.target.checked)
                      }
                    />
                    {item.title}
                  </>
                ),
                actions: (
                  <ActionsDropdown
                    actions={[
                      {
                        label: 'View',
                        icon: <Visibility color="success" />,
                        onClick: () => {
                          setEditDialog({
                            isOpen: true,
                            data: item,
                            forItem: DialogOpenKey,
                          });
                        },
                      },
                      {
                        label: 'Export',
                        icon: <GetAppIcon color="success" />,
                        onClick: () => handleExport(item._id, item.title),
                        disabled: exporting[item._id],
                      },
                    ]}
                  />
                ),
                createdAt: item.createdAt
                  ? getOriginalZonedDate({
                      date: item.createdAt,
                    })
                  : '-',
                status: (
                  <Chip
                    label={item.status}
                    variant="outlined"
                    size="small"
                    color={item.status === 'ACCEPTED' ? 'success' : 'info'}
                  />
                ),
                subRows: [
                  ...(item?.sections?.map((section, index) => {
                    const isSectionSelected = aiRecommended
                      ? true
                      : selectedModules.some(
                          (module) =>
                            module.moduleId === item._id &&
                            module?.sections?.some(
                              (sectionId) => sectionId === section._id
                            )
                        );

                    return {
                      title: (
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{ alignItems: 'center', marginLeft: '20px' }}
                        >
                          <Checkbox
                            key={section._id}
                            size="xs"
                            color="primary"
                            checked={isSectionSelected}
                            disabled={aiRecommended}
                            onClick={(e) =>
                              handleSectionChange(
                                item._id,
                                section._id,
                                e.target.checked
                              )
                            }
                          />
                          <Typography variant="body2">
                            {`Submodule ${index + 1}: ${section.title}`}
                          </Typography>
                        </Stack>
                      ),
                    };
                  }) || []),
                ],
              };
            })}
          />
          <ModuleEditDialog forItem={DialogOpenKey} />
        </>
      )}
    </Stack>
  );
};
