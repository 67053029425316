import { ExpandMore } from '@mui/icons-material';
import TranslateIcon from '@mui/icons-material/Translate';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import apis from 'api/api';
import {
  DynamicLoadingChecklist,
  FormFields,
  VerticalToggleButton,
} from 'components/CustomMUI';

import { useApi } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { languageOptions } from './common/languages';
import ModuleContent from './ModuleContent';

export const ModuleTranslation = ({
  journeyDetails,
  modules = [],
  translatedData = [],
  language = '',
}) => {
  const [showOriginal, setShowOriginal] = useState(true);
  const moduleRef = useRef(null);
  const [translation, setTranslation] = useState({
    language: '',
  });
  const [journeyInfo, setJourneyInfo] = useState(null);
  const [sortedData, setSortedData] = useState([]);

  const [data, setData] = useState([]);

  const { call: createJourneyModuleTranslation, isLoading: isTranslating } =
    useApi({
      fetcher: apis.createJourneyModuleTranslation,
    });

  const { call: getTranslatedModules, isLoading: isFetchingTranslation } =
    useApi({
      fetcher: apis.getTranslatedModules,
    });

  const { batchCall: fetchModuleDetails, isLoading: isModuleDetailsLoading } =
    useApi({
      fetcher: apis.getModuleById,
    });

  const { call: getJourneyDetails, isLoading: journeyIsLoading } = useApi({
    fetcher: apis.getJourney,
  });
  const loading = isTranslating || isFetchingTranslation;
  const isLoadingTranslation = isModuleDetailsLoading || journeyIsLoading;

  const [checklistItems, setChecklistItems] = useState([]);

  const handleFetchModuleDetails = async () => {
    try {
      const moduleRequests = journeyInfo.translatedModule.map(
        async (module) => {
          const originalModuleResponse = await fetchModuleDetails([
            [{ moduleId: module.originalModule }],
          ]);

          const translatedModuleResponse = await fetchModuleDetails([
            [{ moduleId: module.translatedModule }],
          ]);

          const originalData = originalModuleResponse?.[0] || {};
          const translatedData = translatedModuleResponse?.[0] || {};

          return {
            originalModule: originalData,
            translatedModule: translatedData,
          };
        }
      );

      const moduleDetails = await Promise.all(moduleRequests);

      // console.log('Final Mapped Data:', moduleDetails);
      setData(moduleDetails);
    } catch (error) {
      console.error('Error fetching module details:', error);
    }
  };

  useEffect(() => {
    if (journeyDetails) {
      (async () => {
        const journeyId = journeyDetails._id;
        const response = await getJourneyDetails({ journeyId });
        setJourneyInfo(response.data);
      })();
    }
  }, [journeyDetails]);

  useEffect(() => {
    if (journeyInfo) {
      handleFetchModuleDetails();
      setTranslation({
        language: journeyInfo?.targetLanguage,
      });
    }
  }, [journeyInfo]);

  useEffect(() => {
    setChecklistItems([
      {
        id: 'generateTranslation',
        label: `Generating Translation From English To ${translation.language}`,
        isComplete: false,
        apiCall: createJourneyModuleTranslation,
      },
      {
        id: 'getTranslation',
        label: `Fetching ${translation.language} Translation`,
        isComplete: false,
        apiCall: getTranslatedModules,
      },
    ]);
  }, [translation.language]);

  const updateChecklistItem = (itemId, isComplete) => {
    setChecklistItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, isComplete } : item
      )
    );
  };

  const toggleOriginal = () => setShowOriginal(!showOriginal);

  const handleChange = (field, newValue, setValue) => {
    setValue((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  const extractActivities = (module) => {
    const activities = [];
    if (module?.sections) {
      module.sections.forEach((section) => {
        if (section.activity && section.minutes) {
          activities.push({
            name: section.activity,
            duration: section.minutes.toString(),
          });
        }
      });
    }
    return activities;
  };

  useEffect(() => {
    const processedData =
      !data || data.length === 0
        ? modules.map((module) => ({
            originalModule: module,
            translatedModule: {},
          }))
        : [...data].sort((a, b) =>
            a.originalModule.title.localeCompare(b.originalModule.title)
          );

    setSortedData(processedData);
  }, [data]);

  useEffect(() => {
    if (translatedData.length > 0) {
      setData(translatedData);
    }
    if (language) {
      setTranslation({ language: language });
    }
  }, [translatedData, language]);

  const handleTranslate = async () => {
    const tempData = {
      language: translation.language,
    };
    const translateResponse = await checklistItems[0].apiCall({
      data: tempData,
      journeyId: journeyDetails._id,
    });

    updateChecklistItem('generateTranslation', true);

    const translatedResponse = await checklistItems[1].apiCall(
      journeyDetails._id
    );
    updateChecklistItem('getTranslation', true);

    setData(translatedResponse.data);
  };

  const LoadingOverlay = ({ open, message }) => {
    return (
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
        open={open}
      >
        <CircularProgress color="inherit" />
        {message && (
          <Typography variant="h6" align="center">
            {message}
          </Typography>
        )}
      </Backdrop>
    );
  };

  return (
    <Box
      sx={{
        p: 3,
        bgcolor: '#fff',
        minHeight: '100vh',
        border: '1px solid #DAE8E7',
        borderRadius: '8px',
      }}
    >
      {isLoadingTranslation ? (
        <LoadingOverlay
          open={isLoadingTranslation}
          message={'Please wait while fetching translation data..'}
        />
      ) : null}
      <DynamicLoadingChecklist
        isLoading={loading}
        checklistItems={checklistItems}
        title={`Translating data. This may take a few minutes.`}
      />
      <Typography
        variant="h6"
        sx={{ mb: 2, fontWeight: 300, fontSize: '20px' }}
      >
        Translation System
      </Typography>
      <Divider sx={{ border: '1px solid #dae8e7', my: '10px', mb: '30px' }} />

      <Box
        sx={{
          mb: 4,
          gap: 2,
          display: 'flex',
          flexDirection: 'column',
          border: 'none',
        }}
      >
        {modules &&
          modules.length > 0 &&
          modules.map((module, index) => (
            <Accordion
              key={index}
              sx={{
                boxShadow: 'none',
                border: '1px solid #DAE8E7',
                borderRadius: '8px',
                '&::before': { display: 'none' },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>
                  Module {index + 1 + `: `}: {module.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 4, border: 'none' }}>
                {module.sections.map((section, sIndex) => (
                  <Box key={sIndex} sx={{ pl: 2, mb: 2 }}>
                    <Typography sx={{ fontWeight: 500, mb: 1 }}>
                      Section {index + 1 + `: `} {section.title}
                    </Typography>
                    <Box
                      sx={{
                        ml: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                      }}
                    >
                      {section.subSections.map((sm, smIndex) => (
                        <Typography
                          key={smIndex}
                          variant="body2"
                          color="text.secondary"
                        >
                          SM {index + 1 + `: `} {section.title}
                          {sm.title}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            width: '100%',
            height: 50,
          }}
        >
          <FormFields
            type="select"
            name="language"
            label="TARGET LANGUAGE"
            formData={translation}
            canSearch={true}
            handleChange={(name, value) =>
              handleChange(name, value, setTranslation)
            }
            options={languageOptions}
            props={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 400,
                    overflowY: 'auto',
                  },
                },
              },
            }}
          />
        </Box>
        <Button
          variant="outlined"
          color="primary"
          sx={{ minWidth: 180, height: 55 }}
          startIcon={<TranslateIcon />}
          onClick={handleTranslate}
        >
          Translate
        </Button>
      </Box>

      {sortedData.length > 0 && (
        <Box
          sx={{ display: 'flex', position: 'relative', alignItems: 'start' }}
          ref={moduleRef}
        >
          <Box
            sx={{ flex: 1, pr: 3, display: showOriginal ? 'block' : 'none' }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,

                justifyContent: 'start',
              }}
            >
              {sortedData &&
                sortedData.length > 0 &&
                sortedData.map((item, index) => (
                  <ModuleContent
                    title={`Module ${index + 1}`}
                    isTranslated={false}
                    data={item.originalModule}
                    disabled={true}
                    key={`original-${index}`}
                  />
                ))}
            </Box>
          </Box>

          <VerticalToggleButton
            onClick={toggleOriginal}
            isExpanded={showOriginal}
            hideLabel={'HIDE ORIGINAL'}
            showLabel={'SHOW ORIGINAL'}
          />

          <Box sx={{ flex: 1, pl: showOriginal ? 3 : 3 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                justifyContent: 'start',
              }}
            >
              {sortedData &&
                sortedData.length > 0 &&
                sortedData.map((item, index) => (
                  <ModuleContent
                    title={`Module ${index + 1}`}
                    isTranslated={true}
                    data={item.translatedModule}
                    disabled={false}
                    key={`translated-${index}`}
                  />
                ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
