import { Add, Delete, Save } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import apis from 'api/api';
import DeleteDialog from 'components/Dialog/DeleteDialog';
import LoadingOverlay from 'components/LoadingOverlay';
import { useApi } from 'hooks';
import { nanoid } from 'nanoid';
import { FormField } from 'pages/Settings/Tabs/common';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDialogStore } from 'store/useStore';
import { theme } from 'theme/muiTheme';
import { cleanObject } from 'utils';
import { getStyles } from './common/styles';

const ModuleSection = ({ moduleId }) => {
  const [sections, setSections] = useState([]);
  const { sectionCardStyle } = getStyles({ theme });
  const editDialogState = useDialogStore((state) => state.editDialog);
  const setDeleteDialog = useDialogStore((state) => state.setDeleteDialog);

  moduleId = moduleId ? moduleId : editDialogState?.data?._id;

  const {
    call: fetchModule,
    data: moduleDetails,
    isLoading: moduleLoading,
  } = useApi({ fetcher: apis.getModuleById });

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    trigger,
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const clearStoredInfo = () => {
    reset();
    setSections([]);
  };

  const fillStoredData = () => {
    const sectionsToEdit = moduleDetails?.sections ?? [];
    const resetData = {};

    const processSubsections = (subSections) =>
      subSections?.map((subsection) => {
        resetData[`title-${subsection._id}`] = subsection.title;
        resetData[`content-${subsection._id}`] = subsection.content;
        resetData[`description-${subsection._id}`] = subsection.description;

        return {
          id: `${subsection._id}`,
          dbId: subsection._id,
          saved: false,
          title: subsection.title,
          content: subsection.content,
          description: subsection.description,
        };
      }) || [];
    const processedSections = sectionsToEdit.map((section) => {
      resetData[`title-${section._id}`] = section.title;
      resetData[`activity-${section._id}`] = section.activity;
      resetData[`minutes-${section._id}`] = section.minutes;

      return {
        id: `${section._id}`,
        dbId: section._id,
        saved: false,
        title: section.title,
        activity: section.activity,
        minutes: section.minutes,
        subsections: processSubsections(section.subSections),
      };
    });

    if (processedSections.length) {
      setSections(processedSections);
      reset(resetData);
    }
  };

  useEffect(() => {
    if (moduleId) {
      clearStoredInfo();
      fetchModule({ moduleId });
    }
  }, [moduleId]);

  useEffect(() => {
    fillStoredData();
  }, [moduleDetails]);

  const { call: storeSection, isLoading: isSectionCreating } = useApi({
    fetcher: apis.createModuleSection,
    successMessage: 'Section created successfully',
  });

  const { call: updateSection, isLoading: isSectionUpdating } = useApi({
    fetcher: apis.updateModuleSection,
    successMessage: 'Section updated successfully',
  });

  const { call: deleteSection, isLoading: sectionDeleting } = useApi({
    fetcher: apis.deleteModuleSection,
    successMessage: 'Section deleted successfully',
  });

  const { call: storeSubSection, isLoading: isSubSectionCreating } = useApi({
    fetcher: apis.createModuleSubSection,
    successMessage: 'Sub-section created successfully',
  });

  const { call: updateSubSection, isLoading: isSubSectionUpdating } = useApi({
    fetcher: apis.updateModuleSubSection,
    successMessage: 'Sub-section updated successfully',
  });

  const { call: deleteSubSection, isLoading: isSubSectionDeleting } = useApi({
    fetcher: apis.deleteModuleSubSection,
    successMessage: 'Sub-section deleted successfully',
  });

  const handleAddSection = () => {
    const blankSection = {
      id: `sect-${nanoid()}`,
      dbId: null,
      saved: false,
      title: '',
      activity: '',
      minutes: 0,
      subsections: [],
    };
    setSections([...sections, blankSection]);
  };

  const handleDeleteSection = async (section) => {
    if (section.dbId) {
      await deleteSection({
        moduleId,
        sectionId: section.dbId,
      });
    }
    setSections(sections.filter((item) => item.id !== section.id));
  };

  const handleAddSubsection = (section) => {
    const blankSubsection = {
      id: `subsect-${nanoid()}`,
      dbId: null,
      saved: false,
      title: '',
      content: '',
      description: '',
    };

    setSections(
      sections.map((item) =>
        item.id === section.id
          ? {
              ...item,
              subsections: [...item.subsections, blankSubsection],
            }
          : item
      )
    );
  };

  const handleDeleteSubsection = async ({ section, subsection }) => {
    if (subsection.dbId) {
      await deleteSubSection({
        moduleId,
        sectionId: section.dbId,
        subSectionId: subsection.dbId,
      });
    }

    setSections(
      sections.map((item) =>
        item.id === section.id
          ? {
              ...item,
              subsections: item.subsections.filter(
                (sub) => sub.id !== subsection.id
              ),
            }
          : item
      )
    );
  };

  const saveSection = async ({ section }) => {
    const data = cleanObject({
      title: section.title,
      activity: section.activity,
      minutes: Number(section.minutes),
    });

    if (section.dbId) {
      await updateSection({
        data,
        moduleId,
        sectionId: section.dbId,
      });
    } else {
      const response = await storeSection({
        data,
        moduleId,
      });
      handleFieldChange({
        sectionId: section.id,
        fieldName: 'dbId',
        value: response.data._id,
      });
    }
  };

  const saveSubsection = async ({ section, subsection }) => {
    const data = cleanObject({
      title: subsection.title,
      content: subsection.content,
      description: subsection.description,
    });

    if (subsection.dbId) {
      await updateSubSection({
        data,
        moduleId,
        sectionId: section.dbId,
        subSectionId: subsection.dbId,
      });
    } else {
      const response = await storeSubSection({
        data,
        moduleId,
        sectionId: section.dbId,
      });
      handleFieldChange({
        sectionId: section.dbId,
        subsectionId: subsection.id,
        fieldName: 'dbId',
        value: response.data._id,
      });
    }

    handleFieldChange({
      sectionId: section.id,
      subsectionId: subsection.id,
      fieldName: 'saved',
      value: true,
    });
  };

  const handleFieldChange = ({
    sectionId,
    fieldName,
    value,
    subsectionId = null,
  }) => {
    setSections((prevSections) =>
      prevSections.map((section) => {
        if (section.id === sectionId) {
          if (subsectionId) {
            return {
              ...section,
              subsections: section.subsections.map((subsection) =>
                subsection.id === subsectionId
                  ? { ...subsection, [fieldName]: value }
                  : subsection
              ),
            };
          } else {
            return { ...section, [fieldName]: value };
          }
        }
        return section;
      })
    );
  };

  const renderSections = (id) => {
    const infoFields = [
      {
        name: `title-${id}`,
        label: 'Section Category',
        type: 'select',
        options: [
          { label: 'Mindset Warm-ups', value: 'Mindset Warm-ups' },
          { label: 'Investigation', value: 'Investigation' },
          { label: 'Foundations', value: 'Foundations' },
          { label: 'Lab', value: 'Lab' },
          { label: 'Closing The Learning', value: 'Closing The Learning' },
        ],
      },
      {
        name: `activity-${id}`,
        label: 'Activity',
        type: 'text',
      },
      {
        name: `minutes-${id}`,
        label: 'Minute',
        type: 'number',
      },
    ];
    return infoFields.map((item) => (
      <Stack gap={2} direction={'column'} mb={3} key={item.name}>
        <FormField
          formType="ADD"
          key={item.name}
          field={item}
          register={register}
          errors={errors}
          watch={watch}
          hookFormUpdater={setValue}
          hookFormTrigger={trigger}
          onChange={(e) =>
            handleFieldChange({
              sectionId: id,
              fieldName: item.name.split('-')[0],
              value: e.target.value,
            })
          }
        />
      </Stack>
    ));
  };

  const renderSubsections = ({ sectionId, subsectionId }) => {
    const infoFields = [
      {
        name: `title-${subsectionId}`,
        label: 'Title',
        type: 'text',
      },
      {
        name: `description-${subsectionId}`,
        label: 'Description',
        type: 'text',
        multiline: true,
      },
      {
        name: `content-${subsectionId}`,
        label: 'Note',
        type: 'text',
        multiline: true,
      },
    ];

    return (
      <Stack gap={2} direction={'column'}>
        {infoFields.map((item) => (
          <FormField
            formType="ADD"
            key={item.name}
            field={item}
            register={register}
            errors={errors}
            watch={watch}
            hookFormUpdater={setValue}
            hookFormTrigger={trigger}
            onChange={(e) => {
              handleFieldChange({
                sectionId: sectionId,
                subsectionId: subsectionId,
                fieldName: item.name.split('-')[0],
                value: e.target.value,
              });
            }}
          />
        ))}
      </Stack>
    );
  };

  return (
    <Box p={2}>
      {isSectionCreating || isSubSectionCreating ? <LoadingOverlay /> : null}

      {moduleLoading ? (
        <Stack direction={'row'} p={2} justifyContent={'center'}>
          <CircularProgress />
        </Stack>
      ) : (
        sections.map((section, sectionIndex) => (
          <Card key={section.id} sx={sectionCardStyle}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h6">Section {sectionIndex + 1}</Typography>
              <Stack direction={'row'}>
                <Button
                  disabled={section.saved}
                  size="small"
                  color="success"
                  startIcon={<Save />}
                  onClick={() => {
                    saveSection({ section });
                  }}
                >
                  Save
                </Button>

                <Button
                  disabled={section.saved}
                  size="small"
                  color="error"
                  startIcon={<Delete />}
                  onClick={() => {
                    setDeleteDialog({
                      isOpen: true,
                      forItem: 'Module Section',
                      onOk: async () => {
                        await handleDeleteSection(section);
                      },
                    });
                  }}
                >
                  Remove
                </Button>
              </Stack>
            </Box>

            {renderSections(section.id)}

            {section.subsections.map((subsection, subsectionIndex) => {
              const disableAction = section.dbId
                ? false
                : !section.saved || subsection.saved;

              return (
                <Card key={subsection.id} sx={{ ...sectionCardStyle, ml: 2 }}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle1">
                      Subsection {subsectionIndex + 1}
                    </Typography>
                    <Stack direction={'row'}>
                      <Button
                        disabled={disableAction}
                        color="success"
                        size="small"
                        onClick={() => saveSubsection({ section, subsection })}
                        startIcon={<Save />}
                      >
                        Save
                      </Button>

                      <Button
                        disabled={disableAction}
                        color="error"
                        size="small"
                        onClick={() => {
                          setDeleteDialog({
                            isOpen: true,
                            forItem: 'Module Sub-section',
                            onOk: async () => {
                              await handleDeleteSubsection({
                                section,
                                subsection,
                              });
                            },
                          });
                        }}
                        startIcon={<Delete />}
                      >
                        Remove
                      </Button>
                    </Stack>
                  </Box>
                  {renderSubsections({
                    sectionId: section.id,
                    subsectionId: subsection.id,
                  })}
                </Card>
              );
            })}

            <Button
              disabled={!section.dbId}
              variant="outlined"
              fullWidth
              onClick={() => handleAddSubsection(section)}
              startIcon={<Add />}
            >
              Add New Subsection to Section {sectionIndex + 1}
            </Button>
          </Card>
        ))
      )}
      <Button
        variant="outlined"
        fullWidth
        onClick={handleAddSection}
        startIcon={<Add />}
      >
        Add New Section
      </Button>
      <DeleteDialog />
    </Box>
  );
};

export default ModuleSection;
