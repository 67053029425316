import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import React, { useState } from 'react';

export const DataTable = ({
  columns,
  data = [],
  sort = true,
  size = 'medium',
  orderBy = '_id',
  order = 'desc',
}) => {
  const [dataOrder, setDataOrder] = useState(order);
  const [dataOrderBy, setDataOrderBy] = useState(orderBy);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (property) => {
    const isAsc = dataOrderBy === property && dataOrder === 'asc';
    setDataOrder(isAsc ? 'desc' : 'asc');
    setDataOrderBy(property);
  };

  const sortedData = React.useMemo(() => {
    return [...data].sort((a, b) => {
      const valueA = a[dataOrderBy];
      const valueB = b[dataOrderBy];

      if (valueA == null) return dataOrder === 'asc' ? -1 : 1;
      if (valueB == null) return dataOrder === 'asc' ? 1 : -1;

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return dataOrder === 'asc'
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return dataOrder === 'asc'
        ? valueA < valueB
          ? -1
          : valueA > valueB
          ? 1
          : 0
        : valueB < valueA
        ? -1
        : valueB > valueA
        ? 1
        : 0;
    });
  }, [data, dataOrder, dataOrderBy]);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper>
      <TableContainer>
        <Table style={{ tableLayout: 'fixed' }} size={size}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    width: `${100 / columns.length}%`,
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {sort ? (
                    <TableSortLabel
                      active={dataOrderBy === column.id}
                      direction={dataOrderBy === column.id ? dataOrder : 'asc'}
                      onClick={() => handleRequestSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <React.Fragment key={row?.id || row._id || index}>
                    <TableRow
                      hover
                      key={index}
                      sx={{
                        '& > *': {
                          borderBottom: row?.subRows?.length
                            ? 'unset'
                            : '1px solid #ccc',
                        },
                      }}
                    >
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          style={{
                            width: `${100 / columns.length}%`,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {row[column.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                    {row?.subRows?.length
                      ? row.subRows.map((subItem, subIndex) => (
                          <TableRow
                            key={subIndex}
                            sx={{
                              '& > *': {
                                borderBottom:
                                  subIndex === row?.subRows?.length - 1
                                    ? '1px solid #ccc'
                                    : 'unset',
                              },
                            }}
                          >
                            {columns.map((column) => (
                              <TableCell
                                key={column.id}
                                style={{
                                  whiteSpace: 'nowrap',
                                  //overflow: 'hidden',
                                  //textOverflow: 'ellipsis',
                                }}
                              >
                                {subItem[column.id]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      : null}
                  </React.Fragment>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default DataTable;
